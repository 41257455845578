import { Link, useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
// Hooks
import { useDocumentRoutes } from 'hooks/useDocumentRoutes';
import { useMobileScreen } from 'hooks/useMobileScreen';
import { useStorefrontRoutes } from 'hooks/useStorefrontRoutes';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
import { useTypeSelector } from 'hooks/useTypeSelector';
// Types
import { OrderItem } from 'store/storefront/checkout/checkoutTypes';
import { IOrderData } from 'store/storefront/order/orderTypes';
// Utils
import { dateToFormat } from 'utils/dates';
import { calcTaxes } from 'utils/functions';
import { CurrencyFormatByISO, calcTotalFees, calcSubtotal } from 'utils/price';
// Classes
import classes from './Card.module.scss';

interface Props {
  order: IOrderData;
  style?: React.CSSProperties
}

const Card: React.FC<Props> = ({ order, style }) => {
  const { id } = useParams<{ id: string }>();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const { isMobile } = useMobileScreen();
  const translations = useTranslationsStorefront();
  const { getInvoicePath } = useDocumentRoutes();
  const { getOrderPath } = useStorefrontRoutes();
  const formatCurrencyByISO = useMemo(
    () => CurrencyFormatByISO(currentSalesChannel?.address.currencyISO),
    [currentSalesChannel?.address.currencyISO],
  );

  const fulfillmentDetailsFeesCount = useCallback(() => {
    if (order?.fees && order?.fees.length > 0) {
      return order?.fees.reduce((acc, current) => acc + current.amount, 0);
    }

    return 0;
  }, [order?.fulfillment]);

  const taxes = useMemo(() => calcTaxes(order?.items), [order?.items]);
  const subtotalValue = useMemo(() => calcSubtotal(order), [order]);
  const total = useMemo(() => subtotalValue
    - (order?.totalDiscount || 0)
    + calcTotalFees(order?.fees) + taxes.excluded + fulfillmentDetailsFeesCount()!,
  [order?.totalDiscount, order?.fees, fulfillmentDetailsFeesCount, subtotalValue, taxes.excluded]);

  if (isMobile) {
    return (
      <div style={style}>
        <div className={classes.card_mobile}>
          <div className={classes.card_mobile_title}>
            {translations.order.order_details.order_number.replace('{order}', order?.id.substring(0, 8) || '')}
          </div>
          <div className={classes.card_mobile_row}>
            <div className={classes.card_mobile_row_title}>
              {translations.account.card_order.order_placed}
            </div>
            <div className={classes.card_mobile_row_value}>
              {dateToFormat(order.date, ' DD MMM YYYY')}
            </div>
          </div>
          <div className={classes.card_mobile_row}>
            <div className={classes.card_mobile_row_title}>
              {translations.account.card_order.total}
            </div>
            <div className={classes.card_mobile_row_value}>
              {formatCurrencyByISO(total)}
            </div>
          </div>
          <div className={classes.card_links}>
            <Link to={getOrderPath(id, order.id)} className={classes.link}>
              {translations.account.card_order.view_order_details}
            </Link>
            <Link to={getInvoicePath(order.associations?.id || '')} className={classes.link}>
              {translations.account.card_order.view_invoice}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={style}>
      <div className={classes.card}>
        <div className={classes.card_left}>
          <p className={classes.card_left_title}>
            {translations.account.card_order.order_placed}
          </p>
          <p className={classes.card_left_value}>
            {dateToFormat(order.date, ' DD MMM YYYY')}
          </p>
        </div>
        <div className={classes.card_central}>
          <p className={classes.card_central_title}>
            {translations.account.card_order.total}
          </p>
          <p className={classes.card_central_value}>
            {formatCurrencyByISO(total)}
          </p>
        </div>
        <div className={classes.card_right}>
          <p className={classes.card_right_title}>
            {translations.order.order_details.order_number.replace('{order}', order?.id.substring(0, 8) || '')}
          </p>
          <div className={classes.card_links}>
            <Link to={getOrderPath(id, order.id)} className={classes.link}>
              {translations.account.card_order.view_order_details}
            </Link>
            <Link to={getInvoicePath(order.associations?.id || '')} className={classes.link}>
              {translations.account.card_order.view_invoice}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
