import React from 'react';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
// Actions
import { TransactionTypes } from 'store/dashboard/transaction/transactionTypes';
// Types
import { Items, PriceType, TransactionStatus } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
// Utils
import { calcTaxes } from 'utils/functions';
import { CurrencyFormatByISO } from 'utils/price';
// Styles
import classes from './Price.module.scss';

interface Props {
  price: PriceType
  items: Items[]
  translations: TranslationDashboardModel['sidebar_transaction']['price']
}

const Price: React.FC<Props> = ({ price, items, translations }) => {
  const formatCurrencyByISO = CurrencyFormatByISO(price.currencyIso);
  const { detailItem } = useTypeSelector(({ transaction }) => transaction);
  const taxes = calcTaxes(items);
  const isNoPricedQuote = detailItem?.type === TransactionTypes.PURCHASE_ORDER
    && (detailItem.status === TransactionStatus.PENDING
      || detailItem.status === TransactionStatus.EXPIRED);

  if (isNoPricedQuote) {
    return (
      <div className={classes.price}>
        <div>
          <h3>{translations.subtotal}</h3>
          <span>{formatCurrencyByISO('tbd')}</span>
        </div>
        <div className={classes.price_discount}>
          <h3 className={classes.price_discount_title}>{translations.discount}</h3>
          <span className={classes.price_discount_value}>
            {formatCurrencyByISO('tbd')}
          </span>
        </div>
        <div className={classes.taxes_included}>
          <h3 className={classes.taxes_included_label}>{translations.taxes_included}</h3>
          <span>{formatCurrencyByISO('tbd')}</span>
        </div>
        <div>
          <h3>{translations.taxes_excluded}</h3>
          <span>{formatCurrencyByISO('tbd')}</span>
        </div>
        {price?.fees && price.fees.map((fee) => (
          <div key={Object.keys(fee)[0]}>
            <h3>{Object.keys(fee)[0]} {translations.fees}</h3>
            <span>({formatCurrencyByISO('tbd')})</span>
          </div>
        ))}
        <div>
          <h3>{translations.total}</h3>
          <span>{formatCurrencyByISO('tbd')}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.price}>
      <div>
        <h3>{translations.subtotal}</h3>
        <span>{formatCurrencyByISO(price.subtotal)}</span>
      </div>
      <div className={classes.price_discount}>
        <h3 className={classes.price_discount_title}>{translations.discount}</h3>
        <span className={classes.price_discount_value}>
          ({formatCurrencyByISO(price.discount)})
        </span>
      </div>
      <div className={classes.taxes_included}>
        <h3 className={classes.taxes_included_label}>{translations.taxes_included}</h3>
        <span>{formatCurrencyByISO(taxes.included)}</span>
      </div>
      <div>
        <h3>{translations.taxes_excluded}</h3>
        <span>{formatCurrencyByISO(taxes.excluded)}</span>
      </div>
      {price?.fees && price.fees.map((fee) => (
        <div key={Object.keys(fee)[0]}>
          <h3>{Object.keys(fee)[0]} {translations.fees}</h3>
          <span>({(formatCurrencyByISO(fee.amount))})</span>
        </div>
      ))}
      <div>
        <h3>{translations.total}</h3>
        <span>{formatCurrencyByISO(price.total)}
        </span>
      </div>
    </div>
  );
};

export default Price;
