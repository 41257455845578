/* eslint-disable no-nested-ternary */
import TextButton from 'components/Buttons/TextButton';
import { useTypeSelector } from 'hooks/useTypeSelector';
import React from 'react';
import { QuotePriceType, QuoteTermsPaymentType } from 'store/quote/quoteTypes';
import { Items } from 'types/globalTypes';
import { TranslationDashboardModel } from 'types/TranslationDashboardModel';
import { quoteCurrencyFormatByISO } from 'utils/price';
// Styles
import classes from './Price.module.scss';

interface Props {
  price: QuotePriceType
  items: Items[]
  translations: TranslationDashboardModel['sidebar_transaction']['price']
  handleAddDiscount: () => void;
  termsPayment: QuoteTermsPaymentType
}

const Price: React.FC<Props> = ({
  price, translations, handleAddDiscount, termsPayment,
}) => {
  const formatCurrencyByISO = quoteCurrencyFormatByISO(price.currencyIso);
  const { sent } = useTypeSelector((state) => state.quote);

  return (
    <div className={classes.price}>
      <div>
        <h3>{translations.subtotal}</h3>
        <span>{formatCurrencyByISO(price.subtotal)}</span>
      </div>
      {price?.fees && price.fees.map((fee) => (
        <div>
          <h3>{Object.keys(fee)[0]} {translations.fees}</h3>
          <span>{formatCurrencyByISO(fee.amount)}</span>
        </div>
      ))}
      <div>
        <h3>{translations.discount}</h3>
        {(!sent && !price.discount) ? (
          <TextButton
            style={{ textDecoration: 'underline' }}
            onClick={handleAddDiscount}
            disabled={termsPayment.isExpired}
          >
            {translations.add}
          </TextButton>
        ) : (
          !sent
            ? (
              <TextButton
                style={{ textDecoration: 'underline' }}
                onClick={handleAddDiscount}
                disabled={termsPayment.isExpired}
              >
                {formatCurrencyByISO(price.discount)}
              </TextButton>
            ) : (
              <span>
                {formatCurrencyByISO(price.discount)}
              </span>
            )
        )}
      </div>
      <div className={classes.taxes_included}>
        <h3>{translations.taxes_included}</h3>
        <span>{formatCurrencyByISO(price.taxesIncluded)}</span>
      </div>
      <div>
        <h3>{translations.taxes_excluded}</h3>
        <span>{formatCurrencyByISO(price.taxesExcluded)}</span>
      </div>
      <div>
        <h3>{translations.subtotal}</h3>
        <span>{formatCurrencyByISO(price.total)}
        </span>
      </div>
    </div>
  );
};

export default Price;
