import {
  Contact,
  PaymentsMethods,
} from 'store/dashboard/transaction/transactionTypes';

export const replaceKeywordWithTag = (
  string: string,
  keyword: string,
  tagsString = 'span',
): string => {
  const reg = new RegExp(keyword, 'gi');
  const tree: HTMLElement | null = tagsString
    .split('>')
    .reduce((acc: HTMLElement | null, tag) => {
      const tagElement = document.createElement(tag);

      if (!acc) {
        return tagElement;
      }

      acc.appendChild(tagElement);

      return acc;
    }, null);

  if (tree?.lastElementChild) {
    tree.lastElementChild.innerHTML = '$&';
  }

  if (tree && !tree?.lastElementChild) {
    tree.innerText = '$&';
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return string.replace(reg, tree!.outerHTML.replace('&amp;', '&'));
};

export const capitalizeFirstLetter = (
  string: string,
): string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const twoLetterString = (
  string: string,
): string => capitalizeFirstLetter(string.substring(0, 2));

export const lowerFirstLetter = (
  string: string,
): string => string.charAt(0).toLowerCase() + string.slice(1);

export const joinWithArrObjects = (
  arr?: { name: string; variant: string }[],
): string => {
  if (!arr || arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return `${arr[0].name} ${arr[0].variant}`;
  }

  return arr.reduce(
    (acc, curr) => (acc
      ? `${acc} • ${curr.name} ${curr.variant}`
      : `${curr.name} ${curr.variant}`),
    '',
  );
};

export const partialEmail = (email: string): string => email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');

export const getName = (contactId: number, contacts: Contact[]): string => {
  const contact = contacts.filter((user: Contact) => user.id === contactId);
  const { fullname } = contact[0];
  return fullname;
};

export const interpretationPaymentMethod = (
  id: number,
  paymentsMethods: PaymentsMethods[],
): string => {
  const paymentMethod = paymentsMethods.find(
    (e: PaymentsMethods) => e.id === id,
  );
  return paymentMethod?.alias || '';
};
