import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Stripe
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
// Constants
import { PaymentsOptions, PayMethodsEnum } from 'constants/paymentsMethods';
import supportStripeCountries from 'data/support-countries-stripe-list.json';
// Hooks
import { useQuery } from 'hooks/useQuery';
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useTranslationsStorefront } from 'hooks/useTranslationsStorefront';
// Utils
import { PaymentFunctions } from 'utils/storefront/PaymentFunctions';
// Actions
import {
  checkoutSetOrder, checkout, onSuccess, checkoutSetOrderLoadingAction,
} from 'store/storefront/checkout/checkoutActions';
// Types
import { LoadingEnum } from 'store/storefront/checkout/checkoutTypes';
// Assets
import { ReactComponent as BankIcon } from 'assets/payment-icons/bank.svg';
// Components
import Modal from 'components/Modal';
import HeaderModal from 'components/StorefrontComponents/HeaderModal';
import FooterPaymentModal from 'components/StorefrontComponents/Checkout/FooterPaymentModal';
import PaymentTitle from 'components/StorefrontComponents/PaymentTitle';
import Payment from './Payment';
import ExpressPayments from './ExpressPayments';
import PaymentCard from './PaymentCard';
import BankPaymentOptionModal from '../BankPaymentOptionModal';
import PaymentLaterOptionsModal from './PaymentLaterOptionsModal';
// Styles
import classes from './PaymentModal.module.scss';

type Modals = 'bankPayment' | 'payLater' | 'stripe';

interface Props {
  active: boolean;
  setActive: () => void;
}

const PaymentModal: React.FC<Props> = ({ active, setActive }) => {
  const {
    items, fulfillment, order,
  } = useTypeSelector(({ storefront }) => storefront.checkout);
  const { id } = useParams<{ id: string, orderId: string }>();
  const { currentSalesChannel } = useTypeSelector(({ storefront }) => storefront.shop);

  const translations = useTranslationsStorefront();
  const paymentModalTs = translations.checkout.payment_modal;
  const bankPaymentTs = translations.checkout.payment_card_bank_payment;
  const payLaterTs = translations.checkout.payment_card_pay_later;
  const currencyIso = currentSalesChannel?.address.currencyISO || 'EUR';

  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [activeModal, setActiveModal] = useState({
    bankPayment: false,
    payLater: false,
    stripe: false,
  });

  const [type, setType] = useState<PayMethodsEnum | null>(null);
  const checkSupportStripe = useMemo(() => supportStripeCountries.find(
    (country) => country === currentSalesChannel?.address.countryISO,
  ), [currentSalesChannel?.address.countryISO]);

  const banks = useMemo(() => PaymentFunctions.bankPayments(
    PaymentsOptions,
    currentSalesChannel?.address?.countryISO || 'US',
    currencyIso,
  ), [currencyIso, currentSalesChannel?.address?.countryISO]);

  const payLaterOptions = useMemo(() => PaymentFunctions.payLaterPayments(
    PaymentsOptions,
    currentSalesChannel?.address?.countryISO || 'US',
    currencyIso,
    fulfillment.country.iso,
  ), [currencyIso, currentSalesChannel?.address?.countryISO, fulfillment.country.iso]);

  const allPayMethods = useMemo(() => {
    const payMethodsBanks = banks.map((b) => b.name);
    const payMethodsPayLater = payLaterOptions.map((b) => b.name)
      .filter((name) => name !== PayMethodsEnum.PAY_LATER);
    return [...payMethodsBanks, ...payMethodsPayLater, PayMethodsEnum.CARD];
  }, [banks, payLaterOptions]);

  const iStripe = useCallback(
    async () => {
      if (order.stripe?.key && order.stripe?.stripeAccount) {
        setStripePromise(await loadStripe(order.stripe.key, {
          stripeAccount: order.stripe.stripeAccount,
        }));
      } else {
        dispatch(checkoutSetOrder({
          ...order,
          loading: null,
          error: 'Server error',
        }));
      }
    }, [dispatch, order],
  );

  const handleModal = useCallback((modal: Modals) => {
    setActiveModal((prev) => ({
      ...prev,
      bankPayment: false,
      payLater: false,
      stripe: false,
      [modal]: !prev[modal],
    }));
  }, []);

  const handlePayment = useCallback(() => {
    console.log('Handle Stripe');
    handleModal('stripe');
    setActive();
  }, [handleModal, setActive]);

  useEffect(() => {
    if (order.stripe) {
      iStripe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.stripe]);

  const handlePay = useCallback(async (paymentMethodTypes: PayMethodsEnum) => {
    setActive();
    dispatch(checkoutSetOrderLoadingAction(LoadingEnum.PAY));
    if (currentSalesChannel && !order.stripe) {
      const data = await checkout(
        id,
        'INSTANT',
        items,
        fulfillment,
        currentSalesChannel,
        'STRIPE',
        allPayMethods,
      );

      if (!data) {
        dispatch(checkoutSetOrder({
          ...order,
          loading: null,
          error: translations.server_error,
        }));
        return;
      }

      handleModal('stripe');
      setClientSecret(data.payment.client_secret);
      dispatch(checkoutSetOrder({
        loading: null,
        error: undefined,
        isPayed: false,
        id: data.id,
        stripe: {
          stripeAccount: data.payment.stripeAccount,
          key: data.payment.key,
        },
      }));
    }

    setType(paymentMethodTypes);
    if (order.stripe) {
      handleModal('stripe');
      dispatch(checkoutSetOrderLoadingAction(null));
    }
  }, [allPayMethods,
    currentSalesChannel,
    dispatch, fulfillment, handleModal, id, items, order, setActive, translations.server_error]);

  useEffect(() => {
    const cSecret = query.get('payment_intent_client_secret');
    if (cSecret) {
      setClientSecret(cSecret);
    }
  }, [query]);

  useEffect(() => {
    const stripeAcc = query.get('acc');
    const key = query.get('stripe');
    const redirectStatus = query.get('redirect_status');
    if (stripeAcc && key) {
      dispatch(checkoutSetOrder({
        loading: null,
        error: undefined,
        isPayed: false,
        id: order.id,
        stripe: {
          stripeAccount: stripeAcc,
          key,
        },
      }));

      query.delete('acc');
      query.delete('stripe');
      history.replace({
        search: query.toString(),
      });
    }

    if (redirectStatus === 'pending') {
      dispatch(onSuccess(order));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.id, onSuccess, query]);

  return (
    <>
      <Modal
        active={active}
        setActive={setActive}
        className={classes.payment_modal}
      >
        <div className={classes.root}>
          <HeaderModal onClick={setActive} className={classes.header} />
          <PaymentTitle currencyIso={currencyIso} value={fulfillment.amount.total} />
          <p className={classes.subtitle}>
            {paymentModalTs.subtitle}
          </p>
          <div className={classes.payment_cards}>
            <PaymentCard
              title={translations.checkout.payment_card_card_payment.title}
              subtitle={translations.checkout.payment_card_card_payment.subtitle}
              isStripe
              logos={PaymentsOptions.find((option) => option.name === PayMethodsEnum.CARD)?.logos}
              btnTitle={translations.checkout.payment_card_card_payment.btn_title}
              handlePay={handlePay}
              type={PayMethodsEnum.CARD}
            />
            <PaymentCard
              title={bankPaymentTs.title}
              subtitle={bankPaymentTs.subtitle}
              isStripe
              btnTitle={bankPaymentTs.btn_title}
              handleClick={() => handleModal('bankPayment')}
            >
              <BankIcon />
            </PaymentCard>
            <PaymentCard
              title={payLaterTs.title}
              subtitle={payLaterTs.subtitle}
              text={payLaterTs.descr}
              btnTitle={payLaterTs.btn_title}
              isStripe={false}
              handleClick={() => handleModal('payLater')}
            />
          </div>
          <FooterPaymentModal disabled />
        </div>
      </Modal>
      {
        stripePromise && clientSecret && (
          <Elements stripe={stripePromise}>
            <Payment
              active={activeModal.stripe}
              setActive={handlePayment}
              clientSecret={clientSecret}
              type={type}
            />
          </Elements>
        )
      }
      <BankPaymentOptionModal
        clientSecret={clientSecret}
        handlePaymentModal={setActive}
        active={activeModal.bankPayment}
        setActive={() => handleModal('bankPayment')}
        banks={banks}
        setClientSecret={setClientSecret}
        allPayMethods={allPayMethods}
      />
      <PaymentLaterOptionsModal
        active={activeModal.payLater}
        setActive={() => handleModal('payLater')}
        clientSecret={clientSecret}
        allPayMethods={allPayMethods}
        setClientSecret={setClientSecret}
        payLaterOptions={payLaterOptions}
      />
    </>
  );
};

export default PaymentModal;
