import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Hooks
import { useTypeSelector } from 'hooks/useTypeSelector';
import { useQuery } from 'hooks/useQuery';
// Components
import LoadingPage from 'pages/LoadingPage';
import PaymentLinkLayout from 'layouts/PaymentLinkLayout';
import { PaymentLinkFunctions } from 'utils/documents/paymentLinkFunctions';
import { getInvoiceData, invoicePaymentSuccess } from 'store/invoice/invoiceActions';
import Notifications from './Notifications';
import OrderCart from './PaymentLinkPay/OrderCart';
import PaymentModal from '../Invoice/PaymentModal';
// Styles
import classes from './PaymentLink.module.scss';

interface IParams {
  id: string;
  publicId: string;
}

const PaymentLink: React.FC = () => {
  const { id, publicId } = useParams<IParams>();
  const { loading, data, payment } = useTypeSelector((state) => state.invoice);
  const { activeTranslation } = useTypeSelector(({ storefront }) => storefront.app);
  const [openPayModal, setOpenPayModal] = useState(false);
  const currencyIso = data?.organisation.address.currencyISO || 'EUR';
  const dispatch = useDispatch();
  const history = useHistory();
  const totalFees = useMemo(() => {
    if (data?.price.fees) {
      return data?.price.fees.reduce((acc, curr) => acc + curr.amount, 0);
    }
    return 0;
  }, [data?.price.fees]);

  const handleOpenModal = useCallback(() => {
    setOpenPayModal((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!data?.items.length) {
      dispatch(getInvoiceData(id, publicId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data?.termsPayment.remainingBalance) {
      setOpenPayModal(true);
    }
  }, [data?.termsPayment.remainingBalance]);

  useEffect(() => {
    if (payment.success) {
      dispatch(getInvoiceData(id, publicId));
      dispatch(invoicePaymentSuccess(false));
      history.go(0);
    }
  }, [dispatch, history, id, payment.success, publicId]);

  if (!data || loading) {
    return (
      <PaymentLinkLayout>
        <LoadingPage />
      </PaymentLinkLayout>
    );
  }

  const convertedItems = PaymentLinkFunctions.convertItemsToOrderItems(data?.items);

  return (
    <div className={classes.root}>
      <PaymentLinkLayout>
        <PaymentModal
          active={openPayModal}
          setActive={handleOpenModal}
          payAmount={data.price.total}
          organisation={data.organisation}
        />
      </PaymentLinkLayout>
      <OrderCart
        orderInfo={{
          currencyISO: currencyIso,
          total: data.price.total,
          shipping: totalFees,
          discount: data.price.discount,
          itemLength: data.items.length,
          number: data.organisation.id,
          placed: new Date(data.date),
        }}
        items={convertedItems}
        translation={activeTranslation.checkout.price_details}
        translationItem={activeTranslation.cart.cart_item}
        pay
      />
      <Notifications
        payment={payment}
        translation={activeTranslation.checkout.notifications}
        shopName={data.organisation.name}
      />
    </div>
  );
};

export default PaymentLink;
