import { ProductItem } from 'store/dashboard/catalog/items/itemsTypes';
import { AddressType } from 'types/globalTypes';

export const SHOP_SET = 'SHOP_SET';
export const SHOP_SET_CATEGORIES = 'SHOP_SET_CATEGORIES';
export const SHOP_SET_PRODUCTS = 'SHOP_SET_PRODUCTS';
export const SHOP_SET_TO_SEARCH = 'SHOP_SET_TO_SEARCH';
export const SHOP_SET_SEARCH_KEYWORD = 'SHOP_SET_SEARCH_KEYWORD';
export const SHOP_SET_SEARCH_LOADING = 'SHOP_SET_SEARCH_LOADING';
export const SHOP_SET_SEARCH_DATA = 'SHOP_SET_SEARCH_DATA';
export const SHOP_ADD_TO_BAG = 'SHOP_ADD_TO_BAG';
export const SHOP_SET_LOADING = 'SHOP_SET_LOADING';
export const SHOP_SET_ERROR = 'SHOP_SET_ERROR';
export const SHOP_SET_FILTER = 'SHOP_SET_FILTER';
export const SHOP_GET_FILTER = 'SHOP_GET_FILTER';
export const SHOP_CLEAN_FILTER = 'SHOP_CLEAN_FILTER';
export const SHOP_SET_SORT = 'SHOP_SET_SHOP';
export const SHOP_SET_TO_FILTER = 'SHOP_SET_TO_FILTER';
export const SHOP_SET_SALES_CHANNEL = 'SHOP_SET_SALES_CHANNEL';
export const SHOP_SET_TO_PRODUCT_DETAILS = 'SHOP_SET_TO_PRODUCT_DETAILS';
export const SHOP_SET_TO_CONFIRM = 'SHOP_SET_TO_CONFIRM';
export const SHOP_REMOVE_PRODUCT = 'SHOP_REMOVE_PRODUCT';
export const SHOP_SET_PARAMS = 'SHOP_SET_PARAMS';
export const SHOP_SET_CURRENT_SALES_CHANNEL = 'SHOP_SET_CURRENT_SALES_CHANNEL';
export const SHOP_SET_PRODUCT = 'SHOP_SET_PRODUCT';

export interface ShopState {
  shop: Shop | Record<string, never>;
  categories: ShopCategoriesOrProducts<ShopCategory[]>;
  products: ShopCategoriesOrProducts<ProductItem[]>;
  productDetails: ProductItem | null;
  currentSalesChannel: SalesChannel | null;
  salesChannels: SalesChannel[] | null;
  params: IParams[]
  search: {
    data: ProductItem[];
    keyword: string;
    isOpen: boolean;
    loading: boolean;
  };
  filters: Filter[];
  isFilter: boolean;
  sort: ProductSort;
  details: {
    productId: string;
    show: boolean;
  };
  confirm: {
    show: boolean;
    product?: CartItemType;
  }
  bag: CartItemType[];
  loading: boolean;
  error: boolean;
}

export enum ProductSort {
  LATEST = 'latest',
  LOW = 'low',
  HIGH = 'high',
}

export enum NotificationEnum {
  ADD_TO_CART = 'addToCart',
  QUANTITY = 'quantity',
  ERROR_NOT_WALLET = 'errorNotWallet',
  SUCCESS_PAY = 'success'
}

export interface IParams {
  name: string;
  value: boolean | string | ProductSort;
}
export interface Filter {
  name: string;
  value: boolean;
  disabled?: boolean;
}

export interface Shop {
  businessAs: string;
  currencyISO: string
  countryISO: string
}

export interface ICustomerService {
  whatsapp?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  email?: string;
  phoneNumber?: string;
}

export interface IFulfillment {
  shipping: IShippingData[] | [];
  pickup: IPickupData[] | [];
  delivery: IDeliveryData[] | [];
}

export interface SalesChannel {
  id: string;
  name: string;
  logoUrl: string;
  organisationId: string;
  description: string;
  refundPolicy: string;
  address: {
    line1?: string,
    line2?: string,
    country?: string,
    currencyISO?: string,
    countryISO?: string,
    postalCode?: string,
  }
  fulfilment?: IFulfillment;
  checkout: {
    payLater: number;
    paypalEnabled: boolean;
    stripeEnabled: boolean;
    paystackEnabled: boolean;
  };
  email?: string
  customerService?: ICustomerService;
  createdAt: string;
  updatedAt: string;
}

export interface ITradingHours {
  day: number;
  from?: string;
  to?: string;
  closed?: boolean;
}

export interface IShippingData {
  name: string;
  address: AddressType;
  _id: string;
  fee: number;
  provider?: {
    name: string;
    url: string;
  }
}

export interface IPickupData {
  name: string;
  address: AddressType;
  phoneNumber: string;
  email?: string;
  fee?: number;
  tradingHours: ITradingHours[];
}

export interface IDeliveryData {
  _id: string;
  name: string;
  address: AddressType;
  notes: string;
  fee: number;
  areas: { name: string }[];
  deliveryDriver?: {
    name: string,
    phone: string,
  }
}

export interface ShopCategory {
  id: string;
  name: string;
  img?: string;
}

export interface ShopCategoriesOrProducts<
  T extends ShopCategory[] | ProductItem[]
> {
  data: T;
  loading: boolean;
  error: boolean;
  hasNextPage?: boolean;
  searchResult?: boolean;
  page?: string | null;
}

export type CartItemType = ProductItem & { quantity: number };

interface ShopSetAction {
  type: typeof SHOP_SET;
  payload: Shop;
}

interface ShopSetCategoriesAction {
  type: typeof SHOP_SET_CATEGORIES;
  payload: ShopCategoriesOrProducts<ShopCategory[]>;
}

interface ShopSetProductsAction {
  type: typeof SHOP_SET_PRODUCTS;
  payload: ShopCategoriesOrProducts<ProductItem[]>;
}

interface ShopAddToBagAction {
  type: typeof SHOP_ADD_TO_BAG;
  payload: (ProductItem & { quantity: number })[];
}

interface ShopSetToSearchAction {
  type: typeof SHOP_SET_TO_SEARCH;
  payload: boolean;
}

interface ShopSetSearchKeywordAction {
  type: typeof SHOP_SET_SEARCH_KEYWORD;
  payload: string;
}

interface ShopSetSearchLoadingAction {
  type: typeof SHOP_SET_SEARCH_LOADING;
  payload: boolean;
}

interface ShopSetSearchDataAction {
  type: typeof SHOP_SET_SEARCH_DATA;
  payload: ProductItem[];
}

interface ShopSetLoadingAction {
  type: typeof SHOP_SET_LOADING;
  payload: boolean;
}

interface ShopSetErrorAction {
  type: typeof SHOP_SET_ERROR;
  payload: boolean;
}
interface ShopSetFilterAction {
  type: typeof SHOP_SET_FILTER;
  payload: Filter;
}

interface ShopGetFilterAction {
  type: typeof SHOP_GET_FILTER;
  payload: string[];
}

interface ShopCleanAllFilterAction {
  type: typeof SHOP_CLEAN_FILTER;
}

interface ShopSetSortAction {
  type: typeof SHOP_SET_SORT;
  payload: ProductSort;
}

interface ShopSetToFilterAction {
  type: typeof SHOP_SET_TO_FILTER;
  payload: boolean;
}

interface ShopSetSalesChannelAction {
  type: typeof SHOP_SET_SALES_CHANNEL;
  payload: SalesChannel[];
}

interface ShopSetToProductDetailsAction {
  type: typeof SHOP_SET_TO_PRODUCT_DETAILS;
  payload: { show: boolean, productId: string };
}
interface ShopSetToConfirmAction {
  type: typeof SHOP_SET_TO_CONFIRM;
  payload: {
    product?: CartItemType,
    show: boolean
  };
}

interface ShopRemoveProductAction {
  type: typeof SHOP_REMOVE_PRODUCT;
  payload: CartItemType,
}

interface ShopAddParamsAction {
  type: typeof SHOP_SET_PARAMS;
  payload: IParams
}

interface ShopSetCurrentSalesChannelAction {
  type: typeof SHOP_SET_CURRENT_SALES_CHANNEL;
  payload: SalesChannel;
}

interface ShopSetProductAction {
  type: typeof SHOP_SET_PRODUCT;
  payload: ProductItem | null;
}

export type ShopActions =
  | ShopSetAction
  | ShopSetProductAction
  | ShopSetCategoriesAction
  | ShopSetProductsAction
  | ShopAddToBagAction
  | ShopSetToSearchAction
  | ShopSetSearchKeywordAction
  | ShopSetSearchLoadingAction
  | ShopSetSearchDataAction
  | ShopSetLoadingAction
  | ShopSetErrorAction
  | ShopSetFilterAction
  | ShopGetFilterAction
  | ShopCleanAllFilterAction
  | ShopSetSortAction
  | ShopSetToFilterAction
  | ShopSetSalesChannelAction
  | ShopSetToProductDetailsAction
  | ShopSetToConfirmAction
  | ShopRemoveProductAction
  | ShopSetCurrentSalesChannelAction
  | ShopAddParamsAction;
