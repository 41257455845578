import axios from 'axios';
import { auth } from './firebase';

// const baseURL = 'https://core-api.leja.co/v1';
const baseURL = 'https://core-api-dev.leja.co/v1';
export const API = axios.create({
  baseURL,
  withCredentials: true,
});

API.interceptors.request.use(async (config) => {
  if (!auth.currentUser) {
    return config;
  }

  const token = await auth.currentUser.getIdToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const ApiFullHeaders = async <T>(
  url: string,
  organisationPublicId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<T | any> => {
  const { data } = await API.get(url, {
    headers: {
      'leja-organisation-public-id': organisationPublicId,
    },
  });

  return data;
};

export const ApiOrgPublicId = async <T>(
  url: string,
  organisationPublicId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<T | any> => {
  const { data } = await API.get(url, {
    headers: {
      'leja-organisation-public-id': organisationPublicId,
      'accept': 'application/json, text/plain, */*',
    },
  });

  return data;
};

export const ApiOrgPublicIdPatch = async <T>(
  url: string,
  req: unknown,
  organisationPublicId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<T | any> => {
  const { data } = await API.patch(url, req, {
    headers: {
      'leja-organisation-public-id': organisationPublicId,
    },
  });

  return data;
};

export const ApiOrgPublicIdPost = async <T>(
  url: string,
  req: unknown,
  organisationPublicId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<T | any> => {
  const { data } = await API.post(url, req, {
    headers: {
      'leja-organisation-public-id': organisationPublicId,
    },
  });

  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ApiOrgId = async <T>(url: string, organisationId: string): Promise<T | any> => {
  const { data } = await API.get(url, {
    headers: {
      'leja-organisation-id': organisationId,
    },
  });

  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DeleteOrgId = async <T>(url: string, organisationId: string): Promise<T | any> => {
  const { data } = await API.delete(url, {
    headers: {
      'leja-organisation-id': organisationId,
    },
  });

  return data;
};
export const PostOrgId = async <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  url: string, req: unknown, organisationId: number): Promise<T | any> => {
  const { data } = await API.post(url, req, {
    headers: {
      'Content-Type': 'application/json',
      'leja-organisation-id': organisationId,
    },
  });

  return data;
};
